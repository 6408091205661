.animation-wrapper {
  min-height: 50vh;
  display: flex;
  font-size: 10vh;
  justify-content: center;
  align-items: center;
}

.rainbow-container {
  z-index: 1;
  transform: rotate(0deg) translateZ(0);
  transform-origin: center center;
  width: 1em;
  height: 1em;
  -webkit-animation: rainbow 3s infinite linear;
  border-radius: 0.5em;
  box-shadow: 0 0 0.3em 0.05em #c5a7f6, inset 0.03em 0 0.1em 0.02em #de66e4;
  transform-style: preserve-3d;
  perspective: 1em;
}
@keyframes rainbow {
  0% {
    transform: rotate(0deg) translateZ(0);
    box-shadow: 0 0 0.3em 0.05em #c5a7f6, inset 0.03em 0 0.1em 0.02em #de66e4;
  }
  25% {
    transform: rotate(90deg) translateZ(0);
    box-shadow: 0 0 0.3em 0.05em #c5a7f6, inset 0.03em 0 0.1em 0.02em #34ceaa;
  }
  50% {
    transform: rotate(180deg) translateZ(0);
    box-shadow: 0 0 0.3em 0.05em #c5a7f6, inset 0.03em 0 0.1em 0.02em #19b3f5;
  }
  75% {
    transform: rotate(270deg) translateZ(0);
    box-shadow: 0 0 0.3em 0.05em #c5a7f6, inset 0.03em 0 0.1em 0.02em #8280fd;
  }
  100% {
    transform: rotate(360deg) translateZ(0);
    box-shadow: 0 0 0.3em 0.05em #c5a7f6, inset 0.03em 0 0.1em 0.02em #de66e4;
  }
}
.rainbow-container {
  position: relative;
}
.rainbow-container > div {
  filter: blur(2px);
  position: absolute;
  top: 0.1em;
  left: 0.1em;
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
}
.green {
  background: -webkit-linear-gradient(
    left,
    rgba(115, 213, 186, 0.1) 0%,
    rgba(115, 213, 186, 0.4) 60%,
    rgba(115, 213, 186, 0.8) 100%
  );
  transform: rotateY(0) skew(200deg) rotate3d(1.1, 1, 0.9, 0);
  animation: curve-rotate-green 6s infinite linear;
}
.pink {
  background: -webkit-linear-gradient(
    left,
    rgba(255, 115, 229, 0.1) 0%,
    rgba(255, 115, 229, 0.2) 50%,
    rgba(255, 115, 229, 0.8) 100%
  );
  transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 0);
  animation: curve-rotate-pink 6s infinite linear;
}
@-webkit-keyframes curve-rotate-green {
  0% {
    transform: rotateY(0) skew(200deg) rotate3d(1.1, 1, 0.9, 0deg);
  }
  50% {
    transform: rotateY(0) skew(200deg) rotate3d(1.1, 1, 0.9, 180deg);
  }
  100% {
    transform: rotateY(0) skew(200deg) rotate3d(1.1, 1, 0.9, 360deg);
  }
}
@-moz-keyframes curve-rotate-green {
  0% {
    transform: rotateY(0) skew(200deg) rotate3d(1.1, 1, 0.9, 0deg);
  }
  50% {
    transform: rotateY(0) skew(200deg) rotate3d(1.1, 1, 0.9, 180deg);
  }
  100% {
    transform: rotateY(0) skew(200deg) rotate3d(1.1, 1, 0.9, 360deg);
  }
}
@-o-keyframes curve-rotate-green {
  0% {
    transform: rotateY(0) skew(200deg) rotate3d(1.1, 1, 0.9, 0deg);
  }
  50% {
    transform: rotateY(0) skew(200deg) rotate3d(1.1, 1, 0.9, 180deg);
  }
  100% {
    transform: rotateY(0) skew(200deg) rotate3d(1.1, 1, 0.9, 360deg);
  }
}
@keyframes curve-rotate-green {
  0% {
    transform: rotateY(0) skew(200deg) rotate3d(1.1, 1, 0.9, 0deg);
  }
  50% {
    transform: rotateY(0) skew(200deg) rotate3d(1.1, 1, 0.9, 180deg);
  }
  100% {
    transform: rotateY(0) skew(200deg) rotate3d(1.1, 1, 0.9, 360deg);
  }
}
@-webkit-keyframes curve-rotate-pink {
  0% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 0deg);
  }
  50% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 180deg);
  }
  100% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 360deg);
  }
}
@-moz-keyframes curve-rotate-pink {
  0% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 0deg);
  }
  50% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 180deg);
  }
  100% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 360deg);
  }
}
@-o-keyframes curve-rotate-pink {
  0% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 0deg);
  }
  50% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 180deg);
  }
  100% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 360deg);
  }
}
@keyframes curve-rotate-pink {
  0% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 0deg);
  }
  50% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 180deg);
  }
  100% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 360deg);
  }
}
