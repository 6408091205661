.DateInput_input {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #333;
  background-color: white;
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}

.DateInput {
  width: 100%;
}

.SingleDatePickerInput {
  border: none;
  background: none;
}

.DateInput_fang {
  display: none;
}

.DateInput_fang {
  display: none;
}

.DateInput_input__focused {
  border-color: #2684ff;
  box-shadow: 0 0 0 0.5px #2684ff;
}

.SingleDatePicker,
.SingleDatePickerInput {
  width: 100%;
}

.CalendarMonth_caption {
  font-size: 14px;
}

.CalendarDay {
  font-size: 12px;
  border: 1px solid #e5e5e5;
}

.DayPickerNavigation_button {
  padding: 4px;
}

.DayPickerNavigation_svg {
  width: 10px;
  height: 10px;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #ffeb3b;
  border: 1px double #ffeb3b;
  color: #000;
}

.SingleDatePicker_picker {
  border-radius: 8px;
}

.DateRangePicker_picker {
  border-radius: 8px;
}

.DayPicker__withBorder {
  border-radius: 4px;
  min-height: 320px;
}

.DateRangePicker__withBorder {
  border-radius: 8px;
}

.DayPicker_weekHeader {
  top: 56px;
}

.CalendarDay__selected_span {
  background: #e5e5e5;
  color: black;
}

.CalendarDay__selected_span:hover {
  background: #ffeb3b;
  border: 1px double #ffeb3b;
  color: #000;
}

.DateRangePickerInput__withBorder {
  border: none;
  display: flex;
  min-width: 250px;
}

.DateRangePickerInput_arrow_svg {
  width: 14px;
  height: 14px;
  position: relative;
  top: 10px;
  margin: 2px;
}
